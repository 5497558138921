import useFormFactor from "../../hooks/useFormFactor";
import usePosterIOSLowPowerMode from '../../hooks/usePosterIOSLowPowerMode';
import classNames from "classnames";
import type { FormFactor } from "../../config/breakpoints";

type VideoFormat = "webm" | "mov" | "mp4";
type Size = {
  width: number;
  height: number;
};

type Sizes = {
  [key in FormFactor]: Size;
};

interface Props {
  name: string;
  className: string;
  preload?: string;
  formats: VideoFormat[];
  sizes: Sizes;
  loop?: boolean;
  autoplay?: boolean;
  poster?: string;
  posterForIOSLowPowerMode?: boolean;
}

export default function AdaptiveVideo({
  className,
  sizes,
  name,
  preload,
  formats = ["mp4"],
  loop = true,
  autoplay = true,
  poster,
  posterForIOSLowPowerMode = false,
}: Props) {
  const { formFactor } = useFormFactor();
  const shouldAutoplay = usePosterIOSLowPowerMode({autoplay, posterForIOSLowPowerMode, id: name})

  return formFactor ? (
    <video
      key={formFactor}
      className={className}
      playsInline
      autoPlay={shouldAutoplay}
      muted
      loop={loop}
      preload={preload}
      width={sizes[formFactor].width}
      height={sizes[formFactor].height}
      poster={poster}
      id={name}
    >
      {formats.map((format) => (
        <source
          src={`/video/${formFactor}/${name}.${format}`}
          type={`video/${format}`}
        />
      ))}
    </video>
  ) : (
    <>
      <canvas
        className={classNames(className, "only-mobile")}
        width={sizes.mobile.width}
        height={sizes.mobile.height}
      ></canvas>
      <canvas
        className={classNames(className, "only-tablet")}
        width={sizes.tablet.width}
        height={sizes.tablet.height}
      ></canvas>
      <canvas
        className={classNames(className, "only-desktop")}
        width={sizes.desktop.width}
        height={sizes.desktop.height}
      ></canvas>
    </>
  );
}
