import { useEffect } from "preact/hooks";

type PropsType = {
  id: string;
  posterForIOSLowPowerMode: boolean;
  autoplay: boolean;
};

const isIOS = () => {
  if (typeof window === "undefined") return false;
  return (
    window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/iPhone/i)
  );
};

// Handles case for low power mode on iOS: if you don't want to see 'play' control button,
// but to render poster instead, set posterForIOSLowPowerMode to true
export const usePosterIOSLowPowerMode = ({
  id,
  posterForIOSLowPowerMode,
  autoplay,
}: PropsType) => {
  // if posterForIOSLowPowerMode is true, we turn off autoplay of iOS and play video manually
  useEffect(() => {
    if (posterForIOSLowPowerMode) {
      window.requestAnimationFrame(async () => {
        const videoElement = document.getElementById(id);

        if (!(videoElement instanceof HTMLVideoElement)) return;

        const isPlaying =
          videoElement.currentTime > 0 &&
          !videoElement.paused &&
          videoElement.readyState > 2;

        if (!isPlaying) {
          try {
            await videoElement.play();
          } catch (e) {
            console.error("Video error:", e);
          }
        }
      });
    }
  }, []);

  return autoplay && !(isIOS() && posterForIOSLowPowerMode);
};

export default usePosterIOSLowPowerMode;
